@keyframes dash {
	from {
		stroke-dashoffset: 1000;
	  }
	  to {
		stroke-dashoffset: 0;
	  }
}

@keyframes animate {
	100% {
		stroke-dashoffset: 700;
	}
}

@-webkit-keyframes animate {
	100% {
		stroke-dashoffset: 700;
	}
}

@keyframes animateTest {
	100% {
		stroke-dashoffset: 0;
	}
}

@-webkit-keyframes animateTest {
	100% {
		stroke-dashoffset: 0;
	}
}

@keyframes hoverCard {
	0% {
        top: 0;
		left: 0;
    }
    100% {
        top: -10px;
		left: -5px;
    }
}

@-webkit-keyframes hoverCard {
	0% {
        top: 0;
		left: 0;
    }
    100% {
        top: -10px;
		left: -5px;
    }
}

@keyframes unoverCard {
	0% {
		top: -10px;
		left: -5px;
    }
    100% {
		top: 0;
		left: 0;
    }
}

@-webkit-keyframes unoverCard {
	0% {
		top: -10px;
		left: -5px;
    }
    100% {
		top: 0;
		left: 0;
    }
}

@keyframes scale {
	100% {
	  transform: scale(1);
	}
}

@-webkit-keyframes scale {
	100% {
	  transform: scale(1);
	}
}

@keyframes fade-in {
	100% {
	  opacity: 1;
	  filter: blur(0);
	}
}

@-webkit-keyframes fade-in {
	100% {
	  opacity: 1;
	  filter: blur(0);
	}
}