@import 'animations';

@font-face {
	font-family: 'Inter';
	src: local('Inter'),
		url('../fonts/Inter-VariableFont_slnt,wght.ttf') format('truetype');
	font-weight: 100 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Poppins';
	src: url('../fonts/Poppins-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

:root {
	--primary: rgba(162, 200, 58, 0.8);
}

html {
	/* Chrome, Edge, and Safari */
	*::-webkit-scrollbar {
		width: 0px;
	}

	*::-webkit-scrollbar-track {
		background: transparent;
	}

	*::-webkit-scrollbar-thumb {
		background-color: rgba(162, 200, 58, 0.8);
		border-radius: 10px;
	}

	body {
		font-family: 'Poppins', sans-serif;
		overflow-x: hidden;
		scroll-behavior: smooth;
		min-height: 100vh;

		.react-reveal{
			height: 100%;
		}

		.projects-swiper {
			.single-project-link {
				width: 100%;
				aspect-ratio: 234 / 163;
			}
		}

		.about-page {

			.hero{
				@media only screen and (max-width: 768px) {
					padding-block: 100px;
				}
			}

			.swiper {
				overflow: visible;

				@media only screen and (max-width: 768px) {
					overflow: hidden;
				}

				.swiper-wrapper {
					.swiper-slide {
						height: 54.3vh;
						width: 16%;
						padding-bottom: 25px;
						transition: all ease-out 250ms;
						position: relative;
						left: 0;

						@media only screen and (max-width: 1440px) {
							width: 20%;
						}
						@media only screen and (max-width: 1024px) {
							width: 27%;
						}
						@media only screen and (max-width: 768px) {
							width: auto;
						}

						&:nth-child(even) {
							@media only screen and (min-width: 769px) {
								transform: translateY(97px);
							}
						}

						&:last-child {
							left: auto;
							right: 0;
						}

						img {
							border-radius: min(25px, 2vw);
							// height: max(26.302vw, 300px);
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}

						// &:hover {
						// 	@media only screen and (min-width: 769px) {
						// 		width: 35%;
						// 		img {
						// 			// transform: scale(1.05);
						// 		}
						// 	}
						// }
					}
				}
			}
		}
	}
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select {
	font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
	margin: 0;
	// color: #CECECE;
}

a {
	text-decoration: none;
	display: inline-block;
}

.absolute {
	position: absolute;
}
.relative {
	position: relative;
}

.grid {
	display: grid;
	height: 100%;
	width: 100%;
}

.flex {
	display: flex;
}
.wrap {
	flex-wrap: wrap;
}

.flex-column {
	display: flex;
	flex-direction: column;
}
.justify-start {
	justify-content: flex-start;
}

.justify-between {
	justify-content: space-between;
}
.justify-around {
	justify-content: space-around;
}
.justify-center {
	justify-content: center;
}
.justify-evenly {
	justify-content: space-evenly;
}
.align-center {
	align-items: center;
}
.align-end {
	align-items: flex-end;
}
.align-start {
	align-items: flex-start;
}
.absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

*,
*::after,
*::before {
	transition: all ease-out 250ms;
	box-sizing: border-box;
}

//react-grid-layout

.open .react-grid-layout.layout-edit{
	border-radius: 16px;
	box-shadow: 1px 1px 2px rgba(79,79,79,0.3), -1px -1px 2px rgba(33,33,33,0.5), inset -3px 3px 6px rgba(33,33,33,0.2), inset -2px -2px 5px rgba(79,79,79,0.9), inset 3px 3px 10px rgba(33,33,33,0.9);
}
.react-grid-layout {
	position: relative;
	transition: height 200ms ease;
}
.react-grid-item {
	transition: all 200ms ease;
	transition-property: left, top;
}
.react-grid-item.cssTransforms {
	transition-property: transform;
}
.react-grid-item.resizing {
	z-index: 1;
	will-change: width, height;
}
.react-grid-item.react-draggable-dragging {
	transition: none;
	z-index: 3;
	will-change: transform;
}
.react-grid-item.react-grid-placeholder {
	background: var(--primary);
	opacity: 0.2;
	border-radius: 10px;
	transition-duration: 100ms;
	z-index: 2;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}
.react-grid-item > .react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	cursor: se-resize;
}

.noTransform {
	transform: unset !important;
}

//react-select
.react-select-container {
	*::-webkit-scrollbar {
		width: 3px;
	}
}

.logged{
	label[for="themeSwitcher"] {
		display: none;
	}
}

.clients{
	.swiper-wrapper{
		transition-timing-function: linear !important;
	}
}

.projects-swiper{
	.swiper-slide-thumb-active{
		z-index: 1;
	}
}